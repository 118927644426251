import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import {GalleryComponent} from './page/gallery/gallery.component';
import {TechnicComponent} from './page/technic/technic.component';
import {ContactComponent} from './page/contact/contact.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'technic', component: TechnicComponent },
  { path: 'contact', component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
