
export const galleryItems =
  [
    {
      name: 'pot 1',
      img: 'https://images.unsplash.com/photo-1481401908818-600b7a676c0d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80',
      tags: ['white', 'food'],
      date : '01/05/2021',
      description: 'Super produit top top',
      baseLine: 'Super produit',
    },
    {
      name: 'pot 2',
      img: 'https://images.unsplash.com/photo-1525973779373-015bdf68e579?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=667&q=80',
      tags: ['available'],
      date : '01/05/2021',
      description: 'Super produit top top',
      baseLine: 'Super produit',
    },
    {
      name: 'pot 3',
      img: 'https://images.unsplash.com/photo-1536936812504-0e77dc3f0b40?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
      tags: ['available'],
      date : '01/05/2021',
      description: 'Super produit top top',
      baseLine: 'Super produit',
    },
    {
      name: 'pot 4',
      img: 'https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80',
      tags: ['available'],
      date : '01/05/2021',
      description: 'Super produit top top',
      baseLine: 'Super produit',
    }
  ];
export const tags = ['white', 'available', 'food'];
