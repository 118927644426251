<article class="product">
  <div class="thumb" style="background: url({{ product.img }}) no-repeat center; background-size: cover;"></div>
  <div class="infos">
    <h2 class="title">{{ product.name }}</h2>
    <h3 class="date">{{ product.name | date:'MMMM  YYYY' }}</h3>
    <h3 class="seats">{{ product.baseLine }}</h3>
    <p class="txt">
      {{ product.description }}
    </p>
<!--    <h3 class="details">texte texte texte texte texte</h3>-->
  </div>
</article>
