<div class="menu-ctr" >
  <p class="menu-item" [routerLink]="['/']" >
    home <
  </p>
  <p class="menu-item" [routerLink]="['/gallery']" >
    gallerie <
  </p>
  <!--<p class="menu-item" [routerLink]="['/technic']" >
    technique <
  </p>
  <p class="menu-item" [routerLink]="['/contact']" >
    contact <
  </p>-->
</div>
