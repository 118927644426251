import { Component, OnInit } from '@angular/core';
import { galleryItems } from '../../mock-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  galleryItems: any[] = galleryItems;
  filtredItems: any[];
  tags: string[];
  selectedTags: string[] = ['white', 'available', 'food'];

  constructor() { }

  ngOnInit(): void {
    this.tags = ['white', 'available', 'food'];
  }
  handleTagClick(clickedTag) {
    const newSelectedTags = this.selectedTags;
    const indexOfClickedTag = newSelectedTags.indexOf(clickedTag);
    if (indexOfClickedTag === -1) {
      newSelectedTags.push(clickedTag);
    } else {
      newSelectedTags.splice(indexOfClickedTag, 1);
    }
    this.selectedTags = newSelectedTags;
  }

  isTagSelected(tag) {
    let isSelected = false;
    if (this.selectedTags.length > 0) {
      this.selectedTags.forEach( value => {
        if (tag === value) {
          isSelected = true;
        }
      });
    }
    return isSelected;
  }

  showItem(tags) {
    let isSelected = false;
    if (this.selectedTags.length > 0) {
      this.selectedTags.forEach( value => {
        tags.forEach(tag => {
          if (tag === value) {
            isSelected = true;
          }
        });
      });
    }
    return isSelected;
  }
}
