<div class="gallery">
  <h2>Réalisations</h2>
  <div class="filter-bloc">
    <ng-container *ngFor="let tag of tags">
      <div class="filter" [ngClass]="isTagSelected(tag)? 'active' : ''" (click)="handleTagClick(tag)">
        <ng-container *ngIf="isTagSelected(tag); else checked">
          <span class="material-icons">check_box</span>
        </ng-container>
        <ng-template #checked>
          <span class="material-icons">check_box_outline_blank</span>
        </ng-template>
        <p> {{ tag }}</p>
      </div>
    </ng-container>
  </div>
  <div class="product-ctr">
    <ng-container *ngFor="let galleryItem of galleryItems">
      <app-product *ngIf="showItem(galleryItem.tags)" [product]="galleryItem">
      </app-product>
    </ng-container>
  </div>
</div>
