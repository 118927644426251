import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);

import { AppComponent } from './app.component';
import { HomeComponent } from './page/home/home.component';

import { SwiperModule } from 'swiper/angular';
import { MenuComponent } from './ui/menu/menu.component';
import { GalleryComponent } from './page/gallery/gallery.component';
import { TechnicComponent } from './page/technic/technic.component';
import { ContactComponent } from './page/contact/contact.component';
import { ProductComponent } from './ui/product/product.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    GalleryComponent,
    TechnicComponent,
    ContactComponent,
    ProductComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SwiperModule,
  ],
  providers: [ {provide: LOCALE_ID, useValue: "fr-FR" } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
