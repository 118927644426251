<swiper
  [pagination]="{ clickable: true }"
  [slidesPerView]="1"
  [mousewheel]="true"
  [navigation]="true"
  [parallax]="true"
  loop="true"
  (swiper)="onSwiper($event)"
  (slideChange)="onSlideChange()"
>
  <ng-template swiperSlide>
    <div class="slide slide1">
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="slide slide2">

    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="slide slide3">

    </div>
  </ng-template>
</swiper>
